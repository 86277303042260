const importImages = (category, count) => {
  const images = [];
  for (let i = 1; i <= count; i++) {
    try {
      // Attempt to require the image file
      images.push(require(`../assets/${category}${i}.JPG`));
    } catch (error) {
      // If the image doesn't exist, push a placeholder or log the error
      console.warn(`Image ../assets/${category}${i}.JPG not found. Using placeholder.`);
      images.push(require('../assets/MainPage.JPG')); // Ensure you have a placeholder image
    }
  }
  return images;
};

export default importImages;
