import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import importImages from "../utils/ImportImages"; // Adjust the path as needed
import "./Categories.css";

// Import 4 images per category
const couplesImages = importImages("Couples", 7);
const familyImages = importImages("Family", 7);
const graduationImages = importImages("Graduation", 9);
const portraitImages = importImages("Portrait", 7);

// Add other categories if needed
// const businessImages = importImages("Headshot", 3);
// const realEstateImages = importImages("Socials", 2);

const categories = [
  {
    name: "Couples",
    images: couplesImages,
  },
  {
    name: "Graduation",
    images: graduationImages,
  },
  {
    name: "Portraits",
    images: portraitImages,
  },
  {
    name: "Family",
    images: familyImages,
  },
];

function Categories() {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCategoryIndex(
        (prevIndex) => (prevIndex + 1) % categories.length
      );
    }, 8000); // Rotate every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="categories">
      <motion.div
        className="category-rotator"
        key={currentCategoryIndex}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="category-title">
          <h3>{categories[currentCategoryIndex].name}</h3>
        </div>
        <div className="category-gallery">
          {categories[currentCategoryIndex].images.map((image, index) => (
            <div className="category-image" key={index}>
              <img
                src={image}
                alt={`${categories[currentCategoryIndex].name} ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </motion.div>
    </section>
  );
}

export default Categories;
