import React from 'react';
import Portrait from '../assets/SelfPortrait1.JPG';
import './Biography.css';

function Biography() {
  return (
    <section className="biography">
      <h2>About Me</h2>
      <div className="bio-content">
        <div className="bio-image">
          <img src={Portrait} alt="Nadia Ruiz" />
        </div>
        <div className="bio-text">
          <p>412 Production assists clients in realizing their vision by delivering high-quality services at competitive prices, 
            catering to a diverse range of income-generating clients. 
            At 412, we believe in the harmonious balance of quality and affordability.</p>
        </div>
      </div>
    </section>
  );
}

export default Biography;
