import { motion } from 'framer-motion';
import './Hero.css';  // Styling file

function Hero() {
  return (
    <motion.section 
      className="hero"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <h1>412 Production LLC</h1>
      <p>Creative & Business Photography/Video Services</p>
      <button>Call Now</button>
    </motion.section>
  );
}

export default Hero;