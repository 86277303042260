import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import FooterImage from "../assets/Footer.JPG"; // Adjust path if necessary
import "./Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_r84eyfc",
        "template_2bq246q",
        formData,
        "e73ldq0wagh3tuJhl"
      )
      .then((response) => {
        alert("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      })
      .catch((error) => {
        alert("Failed to send message, please try again.");
        console.error("Error:", error);
      });
  };

  return (
    <section className="contact">
      <h2>Contact Us</h2>
      <div className="contact-content">
        <div className="contact-info">
          <h3>Nadia Ruiz</h3>
          <p>📞 254.400.6235</p>
          <p>
            📧{" "}
            <a href="mailto:412ProductionLLC@gmail.com">
              412ProductionLLC@gmail.com
            </a>
          </p>
          <div className="socials">
            <a
              href="https://www.facebook.com/412.photos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/412productionllc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/c/412production4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>

        <div className="contact-form">
          <h3>Get in Touch</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>

        <div className="footer-image">
          <img src={FooterImage} alt="Footer" />
        </div>
      </div>
    </section>
  );
}

export default Contact;
