import React from 'react';
import './Testimonials.css';

const testimonials = [
  { name: 'Michael Ibanez', feedback: 'A true professional! Highly recommended for corporate headshots.' },
  { name: 'Ifeoma Gelor', feedback: '“Called 412 photography at the last minute and they came through for me… Had an amazing photo session with Nadia… She is also so amazing with my 1 year old baby… I will always patronise them…”' },
  { name: 'Nicholas Foster', feedback: 'Captured beautiful moments of our wedding. Thank you so much!' },
];

function Testimonials() {
  return (
    <section className="testimonials">
      <h2>What Clients Say</h2>
      <div className="testimonial-cards">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <p>"{testimonial.feedback}"</p>
            <h4>- {testimonial.name}</h4>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
